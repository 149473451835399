import React from 'react';

export const BasicInfoStep = ({ responses, handleInputChange, nextStep }) => (
    <div>
        <h3>Basic Information</h3>
        <label>Name:</label>
        <input
            type="text"
            name="name"
            placeholder="Enter your name"
            value={responses.name}
            onChange={handleInputChange}
        />
        <label>Email:</label>
        <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={responses.email}
            onChange={handleInputChange}
        />
        {/* <label>Phone:</label>
        <input
            type="tel"
            name="phone"
            placeholder="Enter your phone number"
            value={responses.phone}
            onChange={handleInputChange}
        /> */}
        <button onClick={nextStep}>Next</button>
    </div>
);

export const OwnsCarStep = ({ responses, handleInputChange, prevStep, nextStep }) => (
    <div>
        <h3>Do you currently own a car?</h3>
        <select name="ownsCar" value={responses.ownsCar} onChange={handleInputChange}>
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
        </select>
        <div className="pagination">
            <button onClick={prevStep}>Back</button>
            <button onClick={nextStep}>Next</button>
        </div>
    </div>
);

export const FirstTimeStep = ({ responses, handleInputChange, prevStep, nextStep }) => (
    <div>
        <h3>Is this your first time acquiring a car?</h3>
        <select name="firstTime" value={responses.firstTime} onChange={handleInputChange}>
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
        </select>
        <div className="pagination">
            <button onClick={prevStep}>Back</button>
            <button onClick={nextStep}>Next</button>
        </div>
    </div>
);

export const LeaseOrBuyStep = ({ responses, handleInputChange, prevStep, nextStep }) => {
    const handleLeaseOrBuy = (e) => {
        handleInputChange({
            target: {
                name: 'leaseOrBuy',
                value: e.target.value
            }
        });
        handleInputChange({
            target: {
                name: 'notSureYet',
                type: 'checkbox',
                checked: false
            }
        });
    };

    const handleNotSureYet = (e) => {
        handleInputChange({
            target: {
                name: 'notSureYet',
                type: 'checkbox',
                checked: e.target.checked
            }
        });
        if (e.target.checked) {
            handleInputChange({
                target: {
                    name: 'leaseOrBuy',
                    value: ''
                }
            });
        }
    };

    return (
        <div>
            <h3>Are you looking to Lease or Buy?</h3>
            {!responses.notSureYet && (
                <select 
                    name="leaseOrBuy" 
                    value={responses.leaseOrBuy} 
                    onChange={handleLeaseOrBuy}
                    disabled={responses.notSureYet}
                >
                    <option value="">Select</option>
                    <option value="lease">Lease</option>
                    <option value="buy">Buy</option>
                </select>
            )}
            <div className="not-sure-option">
                <input
                    type="checkbox"
                    id="notSureYet"
                    checked={responses.notSureYet}
                    onChange={handleNotSureYet}
                />
                <label htmlFor="notSureYet">Not Sure Yet</label>
            </div>
            <div className="pagination">
                <button onClick={prevStep}>Back</button>
                <button onClick={nextStep}>Next</button>
            </div>
        </div>
    );
};

export const PreferencesStep = ({ responses, handleInputChange, prevStep, handleSubmit, isLoading }) => (
    <div>
        <h3>Car Preferences</h3>
        <label>Budget:</label>
        <input
            type="number"
            name="budget"
            placeholder="Enter your budget"
            value={responses.budget}
            onChange={handleInputChange}
        />

        <label>Duration:</label>
        <select name="duration" value={responses.duration} onChange={handleInputChange}>
            <option value="">Select duration</option>
            <option value="1-3 years">1-3 years</option>
            <option value="3-5 years">3-5 years</option>
            <option value="5+ years">5+ years</option>
        </select>

        <label>Purpose:</label>
        <select name="purpose" value={responses.purpose} onChange={handleInputChange}>
            <option value="">Select purpose</option>
            <option value="Family">Family</option>
            <option value="Commute">Commute</option>
            <option value="Off-Road">Off-Road</option>
        </select>

        <div className="pagination">
            <button onClick={prevStep}>Back</button>
            <button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Submit'}
            </button>
        </div>
    </div>
);

export const RecommendationsDisplay = ({ recommendations, onStartOver }) => (
    <div className="recommendations-container">
        <h3>Recommendations:</h3>
        <div className="recommendation-cards">
            {recommendations.map((car, index) => (
                <div className="recommendation-card" key={index}>
                    <h4>{car.name}</h4>
                    {car.imageUrl && (
                        <img src={car.imageUrl} alt={car.name} />
                    )}
                    <p><strong>Why?</strong> {car.description}</p>
                    <p><strong>What to Look For:</strong> {car.lookFor}</p>
                </div>
            ))}
        </div>
        <button onClick={onStartOver}>Start Over</button>
    </div>
);