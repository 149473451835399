// CarSelection.js
import React from 'react';
import './CarSelection.css';

const CarSelection = ({ selectedMake, setSelectedMake, selectedModel, setSelectedModel }) => {
    const carOptions = [
        { make: 'Toyota', models: ['Camry', 'Corolla', 'RAV4', 'Highlander'] },
        { make: 'Honda', models: ['Civic', 'Accord', 'CR-V', 'Pilot'] },
        { make: 'Ford', models: ['F-150', 'Explorer', 'Escape', 'Mustang'] },
        { make: 'BMW', models: ['3 Series', '5 Series', 'X3', 'X5'] },
        { make: 'Mercedes', models: ['C-Class', 'E-Class', 'GLC', 'GLE'] }
    ];

    const handleMakeChange = (e) => {
        const make = e.target.value;
        setSelectedMake(make);
        setSelectedModel('');
    };

    return (
        <div className="car-selection">
            <select 
                value={selectedMake} 
                onChange={handleMakeChange}
                className="car-select"
            >
                <option value="">Select Make</option>
                {carOptions.map(car => (
                    <option key={car.make} value={car.make}>
                        {car.make}
                    </option>
                ))}
            </select>

            <select 
                value={selectedModel} 
                onChange={(e) => setSelectedModel(e.target.value)}
                className="car-select"
                disabled={!selectedMake}
            >
                <option value="">Select Model</option>
                {selectedMake && carOptions
                    .find(car => car.make === selectedMake)
                    ?.models.map(model => (
                        <option key={model} value={model}>
                            {model}
                        </option>
                    ))}
            </select>
        </div>
    );
};

export default CarSelection;
