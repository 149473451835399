// Updated Chatbot.js
import React, { useState } from 'react';
import AskQarby from '../components/AskQarby';
import FindYourCar from '../components/FindYourCar';
import '../styles/Chatbot.css';

const Chatbot = () => {
    const [showCards, setShowCards] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleCardClick = (option) => {
        setSelectedOption(option);
        setShowCards(false);
    };

    const handleBack = () => {
        setShowCards(true);
        setSelectedOption(null);
    };

    return (
        <div className={showCards ? 'entrance-container' : 'chatbot-container'}>
            {showCards ? (
                <div className="entrance-cards">
                    <div className="card" onClick={() => handleCardClick('askQarby')}>
                        <div className="card-content">
                            <h2>Ask Qarby</h2>
                            <p>Let's chat about anything cars—maintenance, leasing, financing, first-time buying, and more.</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => handleCardClick('findYourCar')}>
                        <div className="card-content">
                            <h2>Find Your Car</h2>
                            <p>We'd help you find a car that suits your lifestyle, budget and current situation</p>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {selectedOption === 'askQarby' && <AskQarby onBack={handleBack} />}
                    {selectedOption === 'findYourCar' && <FindYourCar onBack={handleBack} />}
                </>
            )}
        </div>
    );
};

export default Chatbot;

// import React, { useState } from 'react';
// import '../styles/Chatbot.css';

// const Chatbot = () => {
//     const [showCards, setShowCards] = useState(true);
//     const [selectedOption, setSelectedOption] = useState(null);
//     const [step, setStep] = useState(1);
//     const [responses, setResponses] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         ownsCar: '',
//         firstTime: '',
//         leaseOrBuy: '',
//         notSureYet: false,
//         budget: '',
//         duration: '',
//         purpose: '',
//     });

//     // For "Find Your Car" recommendations
//     const [chatResponse, setChatResponse] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     // For "Ask Qarby" chat
//     const [userMessages, setUserMessages] = useState([]);
//     const [assistantMessages, setAssistantMessages] = useState([]);
//     const [currentMessage, setCurrentMessage] = useState('');
//     const [isChatLoading, setIsChatLoading] = useState(false);

//     // Car selection state
//     const [selectedMake, setSelectedMake] = useState('');
//     const [selectedModel, setSelectedModel] = useState('');

//     // Problem context state
//     const [problemDuration, setProblemDuration] = useState('');
//     const [problemFrequency, setProblemFrequency] = useState('');
//     const [previousFixes, setPreviousFixes] = useState('');
//     const [problemImpact, setProblemImpact] = useState('');

//     // Car options database
//     const carOptions = [
//         { make: 'Toyota', models: ['Camry', 'Corolla', 'RAV4', 'Highlander'] },
//         { make: 'Honda', models: ['Civic', 'Accord', 'CR-V', 'Pilot'] },
//         { make: 'Ford', models: ['F-150', 'Explorer', 'Escape', 'Mustang'] },
//         { make: 'BMW', models: ['3 Series', '5 Series', 'X3', 'X5'] },
//         { make: 'Mercedes', models: ['C-Class', 'E-Class', 'GLC', 'GLE'] }
//     ];

//     const buildContext = () => {
//         let context = [];
        
//         if (selectedMake && selectedModel) {
//             context.push(`Vehicle: ${selectedMake} ${selectedModel}`);
//         }
//         if (problemDuration) {
//             context.push(`Issue Duration: ${problemDuration}`);
//         }
//         if (problemFrequency) {
//             context.push(`Frequency: ${problemFrequency}`);
//         }
//         if (previousFixes) {
//             context.push(`Previous Attempts: ${previousFixes}`);
//         }
//         if (problemImpact) {
//             context.push(`Impact: ${problemImpact}`);
//         }

//         return context.length > 0 ? `[Context: ${context.join(' | ')}]` : '';
//     };

//     const ProblemContextForm = () => (
//         <div className="problem-context-form">
//             <select 
//                 value={problemDuration} 
//                 onChange={(e) => setProblemDuration(e.target.value)}
//                 className="context-select"
//             >
//                 <option value="">How long has this been happening?</option>
//                 <option value="Just started">Just started</option>
//                 <option value="Few days">Few days</option>
//                 <option value="Few weeks">Few weeks</option>
//                 <option value="Few months">Few months</option>
//                 <option value="Over a year">Over a year</option>
//             </select>

//             <select 
//                 value={problemFrequency} 
//                 onChange={(e) => setProblemFrequency(e.target.value)}
//                 className="context-select"
//             >
//                 <option value="">How often does it occur?</option>
//                 <option value="Constantly">Constantly</option>
//                 <option value="Daily">Daily</option>
//                 <option value="Weekly">Weekly</option>
//                 <option value="Occasionally">Occasionally</option>
//                 <option value="Only in specific conditions">Only in specific conditions</option>
//             </select>

//             <input
//                 type="text"
//                 value={previousFixes}
//                 onChange={(e) => setPreviousFixes(e.target.value)}
//                 placeholder="What have you tried so far?"
//                 className="context-input"
//             />

//             <select 
//                 value={problemImpact} 
//                 onChange={(e) => setProblemImpact(e.target.value)}
//                 className="context-select"
//             >
//                 <option value="">How does this affect your vehicle?</option>
//                 <option value="Vehicle won't start">Vehicle won't start</option>
//                 <option value="Affects performance">Affects performance</option>
//                 <option value="Makes unusual noise">Makes unusual noise</option>
//                 <option value="Warning light on">Warning light on</option>
//                 <option value="Multiple issues">Multiple issues</option>
//             </select>
//         </div>
//     );

//     const handleInputChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setResponses((prevResponses) => ({
//             ...prevResponses,
//             [name]: type === 'checkbox' ? checked : value,
//             ...(name === 'ownsCar' && {
//                 firstTime: '',
//                 leaseOrBuy: '',
//                 notSureYet: false
//             })
//         }));
//     };

//     const nextStep = () => {
//         if (validateStep(step)) {
//             if (step === 2) {
//                 if (responses.ownsCar === 'yes') {
//                     setStep(4);
//                 } else {
//                     setStep(3);
//                 }
//             } else {
//                 setStep(step + 1);
//             }
//         } else {
//             alert('Please answer all questions before proceeding!');
//         }
//     };

//     const prevStep = () => {
//         if (step === 4 && responses.ownsCar === 'yes') {
//             setStep(2);
//         } else {
//             setStep(step - 1);
//         }
//     };

//     const validateStep = (currentStep) => {
//         switch (currentStep) {
//             case 1:
//                 return responses.name && responses.email && responses.phone;
//             case 2:
//                 return responses.ownsCar !== '';
//             case 3:
//                 return responses.firstTime !== '' || responses.ownsCar === 'yes';
//             case 4:
//                 return responses.notSureYet || responses.leaseOrBuy !== '';
//             case 5:
//                 return responses.budget && responses.duration && responses.purpose;
//             default:
//                 return true;
//         }
//     };

//     const handleLeaseOrBuy = (e) => {
//         setResponses((prevResponses) => ({
//             ...prevResponses,
//             leaseOrBuy: e.target.value,
//             notSureYet: false
//         }));
//     };

//     const handleNotSureYet = (e) => {
//         setResponses((prevResponses) => ({
//             ...prevResponses,
//             notSureYet: e.target.checked,
//             leaseOrBuy: e.target.checked ? '' : prevResponses.leaseOrBuy
//         }));
//     };

//     const handleCardClick = (option) => {
//         setSelectedOption(option);
//         setShowCards(false);
//         setStep(1);
//         setResponses({
//             name: '',
//             email: '',
//             phone: '',
//             ownsCar: '',
//             firstTime: '',
//             leaseOrBuy: '',
//             notSureYet: false,
//             budget: '',
//             duration: '',
//             purpose: '',
//         });
//         setChatResponse([]);
//         setUserMessages([]);
//         setAssistantMessages([]);
//         setCurrentMessage('');
//         setSelectedMake('');
//         setSelectedModel('');
//         setProblemDuration('');
//         setProblemFrequency('');
//         setPreviousFixes('');
//         setProblemImpact('');
//     };

//     const handleMakeChange = (e) => {
//         const make = e.target.value;
//         setSelectedMake(make);
//         setSelectedModel('');
//     };

//     const handleModelChange = (e) => {
//         setSelectedModel(e.target.value);
//     };

//     const handleSubmit = async () => {
//         if (!validateStep(step)) {
//             alert('Please complete all questions!');
//             return;
//         }

//         setIsLoading(true);
//         try {
//             const systemPrompt = 'You are a helpful assistant that recommends cars based on user preferences. Ensure your recommendations specify the year of the car and format responses in valid JSON without code blocks or backticks.';

//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer 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`,
//                 },

//                 body: JSON.stringify({
//                     model: 'gpt-4',
//                     messages: [
//                         {
//                             role: 'system',
//                             content: systemPrompt,
//                         },
//                         {
//                             role: 'user',
//                             content: `User details: Name: ${responses.name}, Email: ${responses.email}, Phone: ${responses.phone}. They ${
//                                 responses.ownsCar === 'yes' ? 'own a car' : "don't own a car"
//                             }. ${
//                                 responses.firstTime === 'yes'
//                                     ? 'They are acquiring a car for the first time.'
//                                     : 'They are not a first-time car owner.'
//                             } They ${
//                                 responses.notSureYet 
//                                     ? 'are exploring both leasing and buying options' 
//                                     : `want to ${responses.leaseOrBuy}`
//                             }. Budget: ${responses.budget}, Duration: ${responses.duration}, Purpose: ${
//                                 responses.purpose
//                             }. Please recommend cars in this format: [{"name": "Car Model", "description": "Why this car is good", "lookFor": "Key things to look for"}].`,
//                         },
//                     ],
//                 }),
//             });

//             if (!response.ok) throw new Error(`Error: ${response.status}`);

//             const data = await response.json();
//             const rawContent = data.choices[0].message.content;
//             const sanitizedContent = rawContent.replace(/```(?:json)?/g, '').trim();
//             const recommendations = JSON.parse(sanitizedContent);

//             const recommendationsWithImages = await Promise.all(
//                 recommendations.map(async (car) => {
//                     const imageResponse = await fetch(
//                         `https://carprojfunctionappnew.azurewebsites.net/api/imageSearch?q=${encodeURIComponent(car.name)}`
//                     );
//                     const imageData = await imageResponse.json();
//                     return { ...car, imageUrl: imageData.imageUrl };
//                 })
//             );

//             setChatResponse(recommendationsWithImages);
//         } catch (error) {
//             console.error('Error fetching ChatGPT response:', error);
//             alert('There was an error fetching recommendations. Please try again later.');
//             setChatResponse([]);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleSendMessage = async () => {
//         if (currentMessage.trim() === '') return;

//         const context = buildContext();
//         const userMessageWithContext = context 
//             ? `${context} ${currentMessage}`
//             : currentMessage;

//         setUserMessages((prevMessages) => [...prevMessages, currentMessage]);
//         setCurrentMessage('');
//         setIsChatLoading(true);

//         try {
//             const systemPrompt = `You are Qarby, an expert automotive diagnostic assistant. Your goal is to:
// 1. Help users solve their car problems
// 2. Provide specific, actionable solutions
// 3. Explain why the problem might be occurring
// 4. Mention safety considerations when relevant
// 5. Suggest when professional help is needed

// ${selectedMake && selectedModel 
//     ? `The user has a ${selectedMake} ${selectedModel}. Consider common issues and recalls for this specific model.` 
//     : 'Consider general automotive best practices and common issues.'}

// Format your responses in this structure:
// 1. Problem Assessment
// 2. Potential Causes
// 3. Recommended Solutions
// 4. Safety Notes (if applicable)
// 5. Next Steps

// Be direct and solution-focused while ensuring user safety.`;

//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer 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`,
//                 },

//                 body: JSON.stringify({
//                     model: 'gpt-4',
//                     messages: [
//                         {
//                             role: 'system',
//                             content: systemPrompt,
//                         },
//                         ...userMessages.map(msg => ({ role: 'user', content: msg })),
//                         { role: 'user', content: userMessageWithContext },
//                     ],
//                 }),
//             });

//             if (!response.ok) throw new Error(`Error: ${response.status}`);

//             const data = await response.json();
//             const assistantReply = data.choices[0].message.content.trim();

//             setAssistantMessages((prevMessages) => [...prevMessages, assistantReply]);
//         } catch (error) {
//             console.error('Error fetching ChatGPT response:', error);
//             setAssistantMessages((prevMessages) => [
//                 ...prevMessages,
//                 'Sorry, I encountered an error while processing your request. Please try again later.'
//             ]);
//         } finally {
//             setIsChatLoading(false);
//         }
//     };

//     return (
//         <div className={showCards ? 'entrance-container' : 'chatbot-container'}>
//             {showCards ? (
//                 <div className="entrance-cards">
//                     <div className="card" onClick={() => handleCardClick('askQarby')}>
//                         <div className="card-content">
//                             <h2>Ask Qarby</h2>
//                             <p>Let's chat about anything cars—maintenance, leasing, financing, first-time buying, and more.</p>
//                         </div>
//                     </div>
//                     <div className="card" onClick={() => handleCardClick('findYourCar')}>
//                         <div className="card-content">
//                             <h2>Find Your Car</h2>
//                             <p>We'd help you find a car that suits your lifestyle, budget and current situation</p>
//                         </div>
//                     </div>
//                 </div>
//             ) : (
//                 <>
//                     {selectedOption === 'findYourCar' && (
//                         <>
//                             <div className="progress-bar">
//                                 <div className="progress" style={{ width: `${(step / 5) * 100}%` }}></div>
//                             </div>

//                             {step === 1 && (
//                                 <div>
//                                     <h3>Basic Information</h3>
//                                     <label>Name:</label>
//                                     <input
//                                         type="text"
//                                         name="name"
//                                         placeholder="Enter your name"
//                                         value={responses.name}
//                                         onChange={handleInputChange}
//                                     />
//                                     <label>Email:</label>
//                                     <input
//                                         type="email"
//                                         name="email"
//                                         placeholder="Enter your email"
//                                         value={responses.email}
//                                         onChange={handleInputChange}
//                                     />
//                                     <label>Phone:</label>
//                                     <input
//                                         type="tel"
//                                         name="phone"
//                                         placeholder="Enter your phone number"
//                                         value={responses.phone}
//                                         onChange={handleInputChange}
//                                     />
//                                     <button onClick={nextStep}>Next</button>
//                                 </div>
//                             )}

//                             {step === 2 && (
//                                 <div>
//                                     <h3>Do you currently own a car?</h3>
//                                     <select name="ownsCar" value={responses.ownsCar} onChange={handleInputChange}>
//                                         <option value="">Select</option>
//                                         <option value="yes">Yes</option>
//                                         <option value="no">No</option>
//                                     </select>
//                                     <div className="pagination">
//                                         <button onClick={prevStep}>Back</button>
//                                         <button onClick={nextStep}>Next</button>
//                                     </div>
//                                 </div>
//                             )}

//                             {step === 3 && responses.ownsCar === 'no' && (
//                                 <div>
//                                     <h3>Is this your first time acquiring a car?</h3>
//                                     <select name="firstTime" value={responses.firstTime} onChange={handleInputChange}>
//                                         <option value="">Select</option>
//                                         <option value="yes">Yes</option>
//                                         <option value="no">No</option>
//                                     </select>
//                                     <div className="pagination">
//                                         <button onClick={prevStep}>Back</button>
//                                         <button onClick={nextStep}>Next</button>
//                                     </div>
//                                 </div>
//                             )}

//                             {step === 4 && (
//                                 <div>
//                                     <h3>Are you looking to Lease or Buy?</h3>
//                                     {!responses.notSureYet && (
//                                         <select 
//                                             name="leaseOrBuy" 
//                                             value={responses.leaseOrBuy} 
//                                             onChange={handleLeaseOrBuy}
//                                             disabled={responses.notSureYet}
//                                         >
//                                             <option value="">Select</option>
//                                             <option value="lease">Lease</option>
//                                             <option value="buy">Buy</option>
//                                         </select>
//                                     )}
//                                     <div className="not-sure-option">
//                                         <input
//                                             type="checkbox"
//                                             id="notSureYet"
//                                             checked={responses.notSureYet}
//                                             onChange={handleNotSureYet}
//                                         />
//                                         <label htmlFor="notSureYet">Not Sure Yet</label>
//                                     </div>
//                                     <div className="pagination">
//                                         <button onClick={prevStep}>Back</button>
//                                         <button onClick={nextStep}>Next</button>
//                                     </div>
//                                 </div>
//                             )}

//                             {step === 5 && (
//                                 <div>
//                                     <h3>Car Preferences</h3>
//                                     <label>Budget:</label>
//                                     <input
//                                         type="number"
//                                         name="budget"
//                                         placeholder="Enter your budget"
//                                         value={responses.budget}
//                                         onChange={handleInputChange}
//                                     />

//                                     <label>Duration:</label>
//                                     <select name="duration" value={responses.duration} onChange={handleInputChange}>
//                                         <option value="">Select duration</option>
//                                         <option value="1-3 years">1-3 years</option>
//                                         <option value="3-5 years">3-5 years</option>
//                                         <option value="5+ years">5+ years</option>
//                                     </select>

//                                     <label>Purpose:</label>
//                                     <select name="purpose" value={responses.purpose} onChange={handleInputChange}>
//                                         <option value="">Select purpose</option>
//                                         <option value="Family">Family</option>
//                                         <option value="Commute">Commute</option>
//                                         <option value="Off-Road">Off-Road</option>
//                                     </select>

//                                     <div className="pagination">
//                                         <button onClick={prevStep}>Back</button>
//                                         <button onClick={handleSubmit} disabled={isLoading}>
//                                             {isLoading ? 'Loading...' : 'Submit'}
//                                         </button>
//                                     </div>
//                                 </div>
//                             )}

//                             {chatResponse.length > 0 && (
//                                 <div className="recommendations-container">
//                                     <h3>Recommendations:</h3>
//                                     <div className="recommendation-cards">
//                                         {chatResponse.map((car, index) => (
//                                             <div className="recommendation-card" key={index}>
//                                                 <h4>{car.name}</h4>
//                                                 {car.imageUrl && (
//                                                     <img src={car.imageUrl} alt={car.name} />
//                                                 )}
//                                                 <p><strong>Why?</strong> {car.description}</p>
//                                                 <p><strong>What to Look For:</strong> {car.lookFor}</p>
//                                             </div>
//                                         ))}
//                                     </div>
//                                     <button onClick={() => setShowCards(true)}>Start Over</button>
//                                 </div>
//                             )}
//                         </>
//                     )}

//                     {selectedOption === 'askQarby' && (
//                         <div className="askqarby-container">
//                             <button className="back-button" onClick={() => setShowCards(true)}>← Back</button>
//                             <h3>Ask Qarby</h3>
                            
//                             <div className="car-selection">
//                                 <select 
//                                     value={selectedMake} 
//                                     onChange={handleMakeChange}
//                                     className="car-select"
//                                 >
//                                     <option value="">Select Make</option>
//                                     {carOptions.map(car => (
//                                         <option key={car.make} value={car.make}>
//                                             {car.make}
//                                         </option>
//                                     ))}
//                                 </select>

//                                 <select 
//                                     value={selectedModel} 
//                                     onChange={handleModelChange}
//                                     className="car-select"
//                                     disabled={!selectedMake}
//                                 >
//                                     <option value="">Select Model</option>
//                                     {selectedMake && carOptions
//                                         .find(car => car.make === selectedMake)
//                                         ?.models.map(model => (
//                                             <option key={model} value={model}>
//                                                 {model}
//                                             </option>
//                                         ))}
//                                 </select>
//                             </div>

//                             <ProblemContextForm />

//                             <div className="chat-interface">
//                                 <div className="messages">
//                                     {userMessages.map((msg, index) => (
//                                         <div key={index} className="user-message">
//                                             <strong>You:</strong> {msg}
//                                         </div>
//                                     ))}
//                                     {assistantMessages.map((msg, index) => (
//                                         <div key={index} className="assistant-message">
//                                             <strong>Qarby:</strong> {msg}
//                                         </div>
//                                     ))}
//                                     {isChatLoading && (
//                                         <div className="assistant-message">
//                                             <strong>Qarby:</strong> <em>Typing...</em>
//                                         </div>
//                                     )}
//                                 </div>
//                                 <div className="chat-input">
//                                     <input
//                                         type="text"
//                                         value={currentMessage}
//                                         onChange={(e) => setCurrentMessage(e.target.value)}
//                                         placeholder="Type your question here..."
//                                         onKeyPress={(e) => {
//                                             if (e.key === 'Enter' && !e.shiftKey) {
//                                                 e.preventDefault();
//                                                 handleSendMessage();
//                                             }
//                                         }}
//                                     />
//                                     <button onClick={handleSendMessage} disabled={isChatLoading}>
//                                         Send
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

// export default Chatbot;
