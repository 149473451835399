// FindYourCar.js

import React, { useState } from 'react';
import {
    BasicInfoStep,
    OwnsCarStep,
    FirstTimeStep,
    LeaseOrBuyStep,
    PreferencesStep,
    RecommendationsDisplay
} from './FormSteps';
// import './FindYourCar.css';

const FindYourCar = ({ onBack }) => {
    const [step, setStep] = useState(1);
    const [responses, setResponses] = useState({
        name: '',
        email: '',
        phone: '',
        ownsCar: '',
        firstTime: '',
        leaseOrBuy: '',
        notSureYet: false,
        budget: '',
        duration: '',
        purpose: '',
    });
    const [chatResponse, setChatResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setResponses(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
            ...(name === 'ownsCar' && {
                firstTime: '',
                leaseOrBuy: '',
                notSureYet: false
            })
        }));
    };

    const validateStep = (currentStep) => {
        switch (currentStep) {
            case 1:
                return responses.name && responses.email && responses.phone;
            case 2:
                return responses.ownsCar !== '';
            case 3:
                return responses.firstTime !== '' || responses.ownsCar === 'yes';
            case 4:
                return responses.notSureYet || responses.leaseOrBuy !== '';
            case 5:
                return responses.budget && responses.duration && responses.purpose;
            default:
                return true;
        }
    };

    const handleSubmit = async () => {
        if (!validateStep(step)) {
            alert('Please complete all questions!');
            return;
        }

        setIsLoading(true);
        try {
            const systemPrompt = 'You are a helpful assistant that recommends cars based on user preferences. Ensure your recommendations specify the year of the car and format responses in valid JSON without code blocks or backticks.';

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer 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`,
                },

                body: JSON.stringify({
                    model: 'gpt-4',
                    messages: [
                        { role: 'system', content: systemPrompt },
                        {
                            role: 'user',
                            content: `User details: Name: ${responses.name}, Email: ${responses.email}, Phone: ${responses.phone}. They ${
                                responses.ownsCar === 'yes' ? 'own a car' : "don't own a car"
                            }. ${
                                responses.firstTime === 'yes'
                                    ? 'They are acquiring a car for the first time.'
                                    : 'They are not a first-time car owner.'
                            } They ${
                                responses.notSureYet 
                                    ? 'are exploring both leasing and buying options' 
                                    : `want to ${responses.leaseOrBuy}`
                            }. Budget: ${responses.budget}, Duration: ${responses.duration}, Purpose: ${
                                responses.purpose
                            }. Please recommend cars in this format: [{"name": "Car Model", "description": "Why this car is good", "lookFor": "Key things to look for"}].`,
                        },
                    ],
                }),
            });

            if (!response.ok) throw new Error(`Error: ${response.status}`);

            const data = await response.json();
            const rawContent = data.choices[0].message.content;
            const sanitizedContent = rawContent.replace(/```(?:json)?/g, '').trim();
            const recommendations = JSON.parse(sanitizedContent);

            const recommendationsWithImages = await Promise.all(
                recommendations.map(async (car) => {
                    const imageResponse = await fetch(
                        `https://carprojfunctionappnew.azurewebsites.net/api/imageSearch?q=${encodeURIComponent(car.name)}`
                    );
                    const imageData = await imageResponse.json();
                    return { ...car, imageUrl: imageData.imageUrl };
                })
            );

            setChatResponse(recommendationsWithImages);
        } catch (error) {
            console.error('Error fetching ChatGPT response:', error);
            alert('There was an error fetching recommendations. Please try again later.');
            setChatResponse([]);
        } finally {
            setIsLoading(false);
        }
    };

    const nextStep = () => {
        if (validateStep(step)) {
            if (step === 2) {
                if (responses.ownsCar === 'yes') {
                    setStep(4);
                } else {
                    setStep(3);
                }
            } else {
                setStep(step + 1);
            }
        } else {
            alert('Please answer all questions before proceeding!');
        }
    };

    const prevStep = () => {
        if (step === 4 && responses.ownsCar === 'yes') {
            setStep(2);
        } else {
            setStep(step - 1);
        }
    };

    return (
        <div className="findyourcar-container">
            <button className="back-button" onClick={onBack}>← Back</button>
            
            <div className="progress-bar">
            <div className="progress" style={{ width: `${(step / 5) * 100}%` }}></div>
            </div>

            <div className="step-content">
                {step === 1 && (
                    <BasicInfoStep 
                        responses={responses}
                        handleInputChange={handleInputChange}
                        nextStep={nextStep}
                    />
                )}

                {step === 2 && (
                    <OwnsCarStep 
                        responses={responses}
                        handleInputChange={handleInputChange}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )}

                {step === 3 && responses.ownsCar === 'no' && (
                    <FirstTimeStep 
                        responses={responses}
                        handleInputChange={handleInputChange}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )}

                {step === 4 && (
                    <LeaseOrBuyStep 
                        responses={responses}
                        handleInputChange={handleInputChange}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )}

                {step === 5 && (
                    <PreferencesStep 
                        responses={responses}
                        handleInputChange={handleInputChange}
                        prevStep={prevStep}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                    />
                )}
            </div>

            {chatResponse.length > 0 && (
                <RecommendationsDisplay 
                    recommendations={chatResponse}
                    onStartOver={onBack}
                />
            )}
        </div>
    );
};

export default FindYourCar;