import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Chatbot from './pages/Chatbot';
import Results from './pages/Results';
import Favorites from './pages/Favorites';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { loginRequest } from './loginRequest';

import './styles/App.css';

function App({ msalInstance }) {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        // Handle the redirect after login or logout
        msalInstance.handleRedirectPromise()
            .then((response) => {
                if (response && response.account) {
                    msalInstance.setActiveAccount(response.account);
                    setAccount(response.account);
                } else {
                    // If there's already an active account, set it
                    const activeAccount = msalInstance.getActiveAccount();
                    if (activeAccount) {
                        setAccount(activeAccount);
                    }
                }
            })
            .catch((error) => {
                console.error("Error handling redirect:", error);
            });
    }, [msalInstance]);

    const handleLogin = () => {
        msalInstance.loginRedirect(loginRequest).catch(error => {
            console.error("Login Error:", error);
        });
    };

    const handleLogout = () => {
        msalInstance.logoutRedirect().catch(error => {
            console.error("Logout Error:", error);
        });
    };

    return (
        <Router>
            <Navbar
                account={account}
                onLogin={handleLogin}
                onLogout={handleLogout}
            />
            <Routes>
                {/* Redirect root path to Chatbot */}
                <Route path="/" element={<Navigate to="/chatbot" replace />} />
                <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/results" element={<Results />} />
                <Route path="/favorites" element={<Favorites />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;


// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/Home';
// import Chatbot from './pages/Chatbot';
// import Results from './pages/Results';
// import Favorites from './pages/Favorites';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import { loginRequest } from './loginRequest';

// import './styles/App.css';

// function App({ msalInstance }) {
//     const [account, setAccount] = useState(null);

//     useEffect(() => {
//         // Handle the redirect after login or logout
//         msalInstance.handleRedirectPromise()
//             .then((response) => {
//                 if (response && response.account) {
//                     msalInstance.setActiveAccount(response.account);
//                     setAccount(response.account);
//                 } else {
//                     // If there's already an active account, set it
//                     const activeAccount = msalInstance.getActiveAccount();
//                     if (activeAccount) {
//                         setAccount(activeAccount);
//                     }
//                 }
//             })
//             .catch((error) => {
//                 console.error("Error handling redirect:", error);
//             });
//     }, [msalInstance]);

//     const handleLogin = () => {
//         msalInstance.loginRedirect(loginRequest).catch(error => {
//             console.error("Login Error:", error);
//         });
//     };

//     const handleLogout = () => {
//         msalInstance.logoutRedirect().catch(error => {
//             console.error("Logout Error:", error);
//         });
//     };

//     return (
//         <Router>
//             <Navbar />
//             <div className="auth-controls" style={{ textAlign: 'center', margin: '20px' }}>
//                 {account ? (
//                     <>
//                         <p>Welcome, {account.username}!</p>
//                         <button onClick={handleLogout}>Logout</button>
//                     </>
//                 ) : (
//                     <>
//                         <p>You are not logged in.</p>
//                         <button onClick={handleLogin}>Login</button>
//                     </>
//                 )}
//             </div>

//             <Routes>
//                 <Route path="/" element={<Home />} />
//                 <Route path="/chatbot" element={<Chatbot />} />
//                 <Route path="/results" element={<Results />} />
//                 <Route path="/favorites" element={<Favorites />} />
//             </Routes>
//             <Footer />
//         </Router>
//     );
// }

// export default App;
