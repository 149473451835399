// msalConfig.js
import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "aa3b0406-53bc-47b0-9a8e-7c07fafea091",
    authority: "https://qarby.b2clogin.com/qarby.onmicrosoft.com/B2C_1_signup_signin",
    knownAuthorities: ["qarby.b2clogin.com"],
    redirectUri: "https://qarby.com/", //"http://localhost:3000/",
    postLogoutRedirectUri: "https://qarby.com/" //http://localhost:3000/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose,
    },
  },
};


// // msalConfig.js
// import { LogLevel } from "@azure/msal-browser";

// export const msalConfig = {
//   auth: {
//     clientId: "aa3b0406-53bc-47b0-9a8e-7c07fafea091",
//     authority: "https://qarby.b2clogin.com/qarby.onmicrosoft.com/B2C_1_B2C_1A_signup_signin",
//     knownAuthorities: ["qarby.b2clogin.com"],
//     redirectUri: "http://localhost:3000/",
//     postLogoutRedirectUri: "http://localhost:3000/",
//   },
//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: true,
//   },
//   system: {
//     loggerOptions: {
//       loggerCallback: (level, message, containsPii) => {
//         if (containsPii) return;
//         switch (level) {
//           case LogLevel.Error:
//             console.error(message);
//             break;
//           case LogLevel.Info:
//             console.info(message);
//             break;
//           case LogLevel.Verbose:
//             console.debug(message);
//             break;
//           case LogLevel.Warning:
//             console.warn(message);
//             break;
//           default:
//             break;
//         }
//       },
//       piiLoggingEnabled: false,
//       logLevel: LogLevel.Verbose,
//     },
//   },
// };
