import React from 'react';
import PropTypes from 'prop-types';
import './ProblemContext.css';

const ProblemContextForm = ({ problemContext, setProblemContext }) => {
    return (
        <div className="problem-context-options">
            <select
                className="problem-context-selector"
                value={problemContext.duration}
                onChange={(e) => setProblemContext({ ...problemContext, duration: e.target.value })}
            >
                <option value="">How long has this been happening?</option>
                <option value="1-day">1 Day</option>
                <option value="1-week">1 Week</option>
                <option value="1-month">1 Month</option>
            </select>
            <select
                className="problem-context-selector"
                value={problemContext.frequency}
                onChange={(e) => setProblemContext({ ...problemContext, frequency: e.target.value })}
            >
                <option value="">How often does it occur?</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
            </select>
            <select
                className="problem-context-selector"
                value={problemContext.previousFixes}
                onChange={(e) => setProblemContext({ ...problemContext, previousFixes: e.target.value })}
            >
                <option value="">What have you tried so far?</option>
                <option value="oil-change">Oil Change</option>
                <option value="filter-replacement">Filter Replacement</option>
            </select>
            <select
                className="problem-context-selector"
                value={problemContext.impact}
                onChange={(e) => setProblemContext({ ...problemContext, impact: e.target.value })}
            >
                <option value="">How does this affect your vehicle?</option>
                <option value="engine-noise">Engine Noise</option>
                <option value="performance-issues">Performance Issues</option>
            </select>
        </div>
    );
};

ProblemContextForm.propTypes = {
    problemContext: PropTypes.shape({
        duration: PropTypes.string.isRequired,
        frequency: PropTypes.string.isRequired,
        previousFixes: PropTypes.string.isRequired,
        impact: PropTypes.string.isRequired,
    }).isRequired,
    setProblemContext: PropTypes.func.isRequired,
};

export default ProblemContextForm;
