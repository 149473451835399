// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 

const Navbar = ({ account, onLogin, onLogout, decodedToken }) => {
    console.log("MSAL Account Object:", account); // Add this line
    console.log("Decoded Token:", decodedToken);   // Add this line if using decodedToken

    const getUsername = () => {
        if (!account) return '';
        // Access the name from account.idTokenClaims if available
        return account.name || (account.idTokenClaims && account.idTokenClaims.name) || account.username || account.preferredUsername || '';
    };

    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li><Link to="/">Home</Link></li>
                {/* Uncomment if needed
                <li><Link to="/chatbot">Chatbot</Link></li>
                <li><Link to="/results">Results</Link></li> 
                */}
                <li><Link to="/favorites">Favorites</Link></li>
            </ul>
            <div className="auth-controls">
                {account ? (
                    <>
                        <span className="welcome-message">Welcome, {getUsername()}!</span>
                        <button className="login-button" onClick={onLogout}>Logout</button>
                    </>
                ) : (
                    <button className="login-button" onClick={onLogin}>Login</button>
                )}
            </div>
        </nav>
    );
};

export default Navbar;