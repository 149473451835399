import React from 'react';
// import './styles/Footer.css';
import './Footer.css'; // Update this


const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2024 qarby. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
