import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/Results.css';

const Results = () => {
    const location = useLocation();
    const { budget } = location.state; // Removed unused 'duration' and 'purpose'

    // Mock data
    const cars = [
        { id: 1, make: 'Toyota', model: 'Camry', price: 20000, description: 'Great for families.', image: '/images/camry.jpg' },
        { id: 2, make: 'Honda', model: 'Civic', price: 18000, description: 'Perfect for commuting.', image: '/images/civic.jpg' },
        { id: 3, make: 'Jeep', model: 'Wrangler', price: 35000, description: 'Ideal for off-road adventures.', image: '/images/wrangler.jpg' },
    ];

    const filteredCars = cars.filter((car) => car.price <= budget);

    return (
        <div className="results-container">
            <h1>Recommended Cars</h1>
            <div className="car-grid">
                {filteredCars.length > 0 ? (
                    filteredCars.map((car) => (
                        <div key={car.id} className="car-card">
                            <img src={car.image} alt={`${car.make} ${car.model}`} className="car-image" />
                            <h2>{car.make} {car.model}</h2>
                            <p>Price: ${car.price}</p>
                            <p>{car.description}</p>
                        </div>
                    ))
                ) : (
                    <p>No cars match your criteria. Try different inputs.</p>
                )}
            </div>
        </div>
    );
};



export default Results;
