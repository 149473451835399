import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import CarSelection from './CarSelection';
import ProblemContextForm from './ProblemContext';
import './AskQarby.css';

const AskQarby = ({ onBack }) => {
    const [userMessages, setUserMessages] = useState([]);
    const [assistantMessages, setAssistantMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [queryType, setQueryType] = useState('general');
    const [problemContext, setProblemContext] = useState({
        duration: '',
        frequency: '',
        previousFixes: '',
        impact: ''
    });

    const handleSendMessage = async () => {
        if (currentMessage.trim() === '') return;

        const context = buildContext();
        const userMessageWithContext = context 
            ? `${context} ${currentMessage}`
            : currentMessage;

        setUserMessages(prev => [...prev, currentMessage]);
        setCurrentMessage('');
        setIsChatLoading(true);

        try {
            let systemPrompt = `You are Qarby, an expert automotive assistant. Format your responses using Markdown for better readability:

1. Use bold (**text**) for important terms and key points
2. Use sections with clear headings (## Section) for organized responses
3. Use numbered lists (1., 2., etc.) for sequential steps
4. Use bullet points for non-sequential items
5. Use code blocks (\`text\`) for specific part numbers or technical specifications
6. Use > for important warnings or safety notices
7. Include a "Safety Note" section when relevant
8. Include a "Next Steps" section when appropriate
9. Format any costs or measurements consistently

For diagnostic responses, always include these sections:
## Possible Causes
## Diagnostic Steps
## Solution Options
## Safety Considerations (if applicable)
## Estimated Costs (if applicable)
## Professional Help (when to seek it)

${selectedMake && selectedModel 
    ? `The user has a ${selectedMake} ${selectedModel}. Consider specific information for this model when relevant.` 
    : 'Consider general automotive knowledge and best practices.'}

${queryType === 'problem' 
    ? 'The user is describing a specific problem with their vehicle. Focus on diagnostics and solutions.' 
    : 'The user may be asking for general information, maintenance tips, or explanations about automotive topics.'}`;

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer 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`,
                },

                body: JSON.stringify({
                    model: 'gpt-4',
                    messages: [
                        { role: 'system', content: systemPrompt },
                        ...userMessages.map(msg => ({ role: 'user', content: msg })),
                        { role: 'user', content: userMessageWithContext },
                    ],
                }),
            });

            if (!response.ok) throw new Error(`Error: ${response.status}`);

            const data = await response.json();
            const assistantReply = data.choices[0].message.content.trim();

            setAssistantMessages(prev => [...prev, assistantReply]);
        } catch (error) {
            console.error('Error fetching ChatGPT response:', error);
            setAssistantMessages(prev => [
                ...prev,
                'Sorry, I encountered an error while processing your request. Please try again later.'
            ]);
        } finally {
            setIsChatLoading(false);
        }
    };

    const buildContext = () => {
        let context = [];

        if (selectedMake && selectedModel) {
            context.push(`Vehicle: ${selectedMake} ${selectedModel}`);
        }

        if (queryType === 'problem') {
            Object.entries(problemContext).forEach(([key, value]) => {
                if (value) context.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`);
            });
        } else if (queryType === 'general') {
            context.push("General automotive inquiry");
        }

        return context.length > 0 ? `[Context: ${context.join(' | ')}]` : '';
    };

    const formatMessage = (message, isAssistant) => {
        if (!isAssistant) {
            return <div className="message-content">{message}</div>;
        }

        return (
            <div className="message-content markdown-content">
                <ReactMarkdown>{message}</ReactMarkdown>
            </div>
        );
    };

    const placeholderText = queryType === 'problem' 
        ? "Describe your car problem..."
        : "Ask any car-related question...";

    return (
        <div className="askqarby-container">
            <button className="back-button" onClick={onBack}>← Back</button>
            <h3>Ask Qarby</h3>

            <div className="query-type-selector">
                <div className="query-type-options">
                    <button 
                        className={`query-type-button ${queryType === 'general' ? 'active' : ''}`}
                        onClick={() => setQueryType('general')}
                    >
                        General Question
                    </button>
                    <button 
                        className={`query-type-button ${queryType === 'problem' ? 'active' : ''}`}
                        onClick={() => setQueryType('problem')}
                    >
                        Car Problem
                    </button>
                </div>
            </div>

            <CarSelection 
                selectedMake={selectedMake}
                setSelectedMake={setSelectedMake}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
            />

            {queryType === 'problem' && (
                <ProblemContextForm 
                    problemContext={problemContext}
                    setProblemContext={setProblemContext}
                />
            )}

            <div className="chat-interface">
                <div className="messages">
                    {userMessages.map((msg, index) => (
                        <div key={index} className="user-message">
                            <div className="message-header">
                                <strong>You</strong>
                            </div>
                            {formatMessage(msg, false)}
                        </div>
                    ))}
                    {assistantMessages.map((msg, index) => (
                        <div key={index} className="assistant-message">
                            <div className="message-header">
                                <strong>Qarby</strong>
                            </div>
                            {formatMessage(msg, true)}
                        </div>
                    ))}
                    {isChatLoading && (
                        <div className="assistant-message">
                            <div className="message-header">
                                <strong>Qarby</strong>
                            </div>
                            <div className="message-content">
                                <em>Typing...</em>
                            </div>
                        </div>
                    )}
                </div>
                <div className="chat-input">
                    <input
                        type="text"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        placeholder={placeholderText}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                    />
                    <button onClick={handleSendMessage} disabled={isChatLoading}>
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AskQarby;