import React from 'react';
import '../styles/Favorites.css';

const Favorites = () => {
    // Mock saved cars
    const savedCars = [
        { id: 1, make: 'Toyota', model: 'Camry', price: 20000 },
        { id: 2, make: 'Honda', model: 'Civic', price: 18000 },
    ];

    return (
        <div className="favorites-container">
            <h1>Your Saved Cars</h1>
            <div className="car-grid">
                {savedCars.map((car) => (
                    <div key={car.id} className="car-card">
                        <h2>{car.make} {car.model}</h2>
                        <p>Price: ${car.price}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Favorites;
